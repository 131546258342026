// import { Model } from '@vuex-orm/core';
import PlatformMatchingPattern from './platformMatchingPattern';
import VuexStore from '@/store/index'
import AsyncModel from './asyncModel';

export default class Platform extends AsyncModel {
    static entity = 'platforms';

    static fields() {
        return {
            id: this.attr(null),
            name: this.attr(null),
            url: this.attr(null),
            status: this.attr(null),
            priority: this.attr(null),
            platform_matching_patterns: this.hasMany(PlatformMatchingPattern, 'platform_id'),
        }
    }

    static apiConfig = {
        baseURL: 'api/v2/platforms',
        dataKey: 'data'
    }

    static async loadPlatforms(params = null, force_request = false) {
        if(!force_request && (VuexStore.state.entities[this.entity].initialized || VuexStore.state.entities[this.entity].loading)) {
            return;
        }

        const default_params = {
            // filter: {
            //     status: 1,
            // },
            page_size: 10000,
        }
        await Platform.api().get("", {
            params: params ?? default_params
        })
    }

    static getAllPlatforms() {
        return Platform.query().get()
    }

    static getActivePlatforms() {
        return Platform.query()
            .where('status', 1).get()
    }
}