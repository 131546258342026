import axios from '../axiosMime'
// import Echo from 'laravel-echo'
import moment from 'moment';
import qs from 'qs'
import verification from './verification/actions'
import holidayTheme from './holiday_theme/actions'
import counterfeitchecker from './cm/counterfeitchecker/actions';
import { getHeaders, getImageCDN } from '@/utils/common';
import { getGui, getGuiSession } from '@/utils/session';
import { useFeatureAccessStore } from './pinia/feature-access';

export default {
    async getUserQuota({commit}) {
        let queries = {
            include: ['weeklyQuota.pendingUpdates', 'weeklyQuotaGroup.pendingUpdates', 'progressCount']
        }
        await axios.get(`${process.env.VUE_APP_URL}/api/v2/users/${getGuiSession()?.id}?${qs.stringify(queries, { encodeValuesOnly: true })}`, getHeaders())
        .then(({ data }) => {
            commit('setUserQuota', { current: data?.progress_count, target: data.weekly_quota?.target, info: data })
        })
    },
    isProfileFetching() {
        return false
    },
    async getUserProfile({commit}) {
        if(getGuiSession()) {
            if(this.isProfileFetching) {
                return
            }
            await axios.get(`${process.env.VUE_APP_URL}/user_profile`, getHeaders())
            .then( response => {
                this.isProfileFetching = true
                commit('setUserProfile', response.data.data[0])
                setTimeout(() => {
                    this.isProfileFetching = false
                }, 10000)
            })
            .catch(err => {
                this.$parent.showSnackbar('Something went wrong. Please contact system admin.', false, 'invalid', 'fas fa-times-circle')
            })
        }

    },
    increaseUserCurrentWeeklyQuota({commit}) {
        commit('increaseUserCurrentWeeklyQuota')
    },
    setUserName({commit},username){
        commit("SET_USERNAME", username)
    },
    showNavbar({commit},value){
        commit("SET_NAVBAR",value)
    },
    showSidebar({commit},value){
        commit("SET_SIDEBAR",value)
    },
    showFilterDrawer({commit},value){
        commit("SET_FILTERDRAWER",value)
    },
    greyBg({commit}, value) {
        commit("SET_GREYBG", value)  
    },
    changeNavbarType({commit},value){
        commit("SET_NAVTYPE",value)
    },
    changeAddedType({commit},type){
        let value = type == "add" ? true : false
        commit("SET_ADDEDTYPE",value)
    },
    setSellerID({commit},value){//Setting the seller id during manual addition
        commit("SET_SELLERID",value)
    },
    setListingID({commit},value){
        commit("SET_LISTINGID",value)
    },
    getCampaigns({state,commit}){
        axios.get(process.env.VUE_APP_URL+"/campaigns",state.JSON.parse(JSON.parse(localStorage.getItem("ver")).headers))
            .then(response=>{
                let results = response.data
                commit("SET_CAMPAIGNS",results.data)
            })
    },
    setFilters({commit},filters){
        commit("SET_FILTERS",filters,{root: true})
    },
    showListingInfo({commit},value){//Showing listing info during manual addition
        commit("DISPLAY_LISTINGINFO",value)
    },
    showEvidence({commit},value){
        commit("DISPLAY_EVIDENCES",value)
    },
    enableTabs({commit},obj){
        commit("ENABLE_TABS",obj)
    },
    allowExitInManulAddition({commit},value){
        commit("ALLOW_EXIT",value)
    },
    setActiveListingStatus({ commit }, value) {
        commit("SET_ACTIVE_LISTING_STATUS", value)
    },
    getActiveListingInfo({commit,dispatch,state},lid){
        dispatch('cancelRequests')
        dispatch('refreshCancelToken')

        let config       = JSON.parse(JSON.parse(localStorage.getItem(location.pathname.includes('/lawfirm') ? "lawfirm" : "ver")).headers)
        config.cancelToken = state.cancellationTokenSource.token
        
        let listing_info = {}
        dispatch('setActiveListingStatus', false)
        return axios.get(process.env.VUE_APP_URL+`/listings/${lid}`,config).then(response=>{
            let results = response.data
            listing_info = results.data.map(list => {
                let seller = (typeof list.seller[0] !== "undefined")
                    ? list.seller[0]
                    : { // if seller is none
                        id: 0,
                        campaign_id: 0,
                        platform_id: 0,
                        name: "",
                        site_id: "",
                        url: "",
                        contactable: 0,
                        seller_id: 0,
                        seller_info: {
                            contact_person: "",
                            mobile_no: "",
                            telephone: "",
                            e_mail: "",
                            script: "",
                            first_contact_date: "",
                            vpn_location: "",
                            convo_method: 0,
                            country: "",
                            seller_country_id: 0,
                            region: "",
                            seller_region_id: 0,
                            address: "",
                            ship_to_us: 1,
                            notes: ""
                        }
                    }
                return {
                    // "seller_id"         : seller.id,
                    "seller_id"         : seller.seller_id ?? seller.id,
                    "seller_campaign_id": seller.campaign_id,
                    "site_id"           : seller.site_id,
                    "seller_platform"   : seller.platform_id, 
                    "is_contactable"    : seller.contactable,
                    "seller_name"       : seller.name,
                    "rid"               : list.id,
                    "status"            : list.status,
                    "list_title"        : list.listing_info.listing_title,
                    "list_url"          : list.url,
                    "list_platform"     : list.platform,
                    "keyword"           : list.keyword,
                    "claim_type_id"     : list.claim_type_id != null ? list.claim_type_id : 0,
                    "claim_type"        : list.claimtype ? list.claimtype : "",
                    "store_search_keyword"  : list.store_search_keyword,
                    "crawl_query_id"    : list.crawl_query_id,
                    "trans_history"     : list.listing_info.transaction_history,
                    "seller_trans_history" : seller.seller_info.transact_history != null ? seller.seller_info.transact_history : seller.seller_info.transaction_history,
                    "seller_url"        : seller.url,//
                    "item_type"         : list.listing_info.item_type,
                    "dummy_name"        : (list.listing_info.dummy_account != null) ? (list.listing_info.dummy_account.length > 0 ? list.listing_info.dummy_account[0].da_user_id : "-----") : "-----",
                    "dummy_used"        : (list.listing_info.dummy_account != null) ? (list.listing_info.dummy_account.length > 0 ? list.listing_info.dummy_account[0].da_id : 0) : 0,
                    "dummy_dummyname"   : (list.listing_info.dummy_account != null) ? (list.listing_info.dummy_account.length > 0 ? list.listing_info.dummy_account[0].da_name : "-----") : "-----",
                    "dummy_pass"        : (list.listing_info.dummy_account != null) ? (list.listing_info.dummy_account.length > 0 ? list.listing_info.dummy_account[0].da_pass : "-----") : "-----",
                    "reasons_list"      : (list.listing_info.reasons_with_ids != null) ?  list.listing_info.reasons_with_ids.filter(reason=>{return reason != null}).map(reason=>{return reason.title}) : [],
                    "reasons"           : (list.listing_info.reasons_with_ids != null) ?  list.listing_info.reasons_with_ids.filter(reason=>{return reason != null}).map(reason=>{return reason.id}) : [],
                    "contact_person"    : (seller.seller_info.contact_person != null) ? seller.seller_info.contact_person : "",
                    "mobile_no"         : (seller.seller_info.mobile_no != null) ? seller.seller_info.mobile_no : "",
                    "telephone"         : (seller.seller_info.telephone != null) ? seller.seller_info.telephone: "",
                    "e_mail"             : (seller.seller_info.e_mail != null) ? seller.seller_info.e_mail : "",
                    "script"            : (seller.seller_info.script != null) ? seller.seller_info.script : "",
                    "aliases"           : list.aliases.length > 0 ? list.aliases : [],
                    "first_contact"     : (seller.seller_info.first_contact_date != null) ? seller.seller_info.first_contact_date : "",
                    "vpn_location"      : (seller.seller_info.vpn_location != null) ? seller.seller_info.vpn_location : "",
                    "convo_method"      : (seller.seller_info.convo_method != null) ? (seller.seller_info.convo_method ? seller.seller_info.convo_method : 0) : 0,
                    "payment_accounts"  : (seller.seller_accounts != null && seller.seller_accounts.length > 0) ? seller.seller_accounts.map(accnt=>{return{id:accnt.id,name_email: accnt.name_email,number: accnt.number,type:accnt.type,type_name:accnt.payment_type}}) : [],
                    "country_name"      : (seller.seller_info.country != null) ? seller.seller_info.country : "",
                    "country"           : (seller.seller_info.country_id != null) ? (seller.seller_info.country_id ? seller.seller_info.country_id : 0) : 0,
                    "region_name"       : (seller.seller_info.region != null) ? seller.seller_info.region : "",    
                    "region"            : (seller.seller_info.region_id != null) ? (seller.seller_info.region_id ? seller.seller_info.region_id : 0) : 0,
                    "address"           : (seller.seller_info.address != null) ? seller.seller_info.address : "",
                    "ship_to_us"        : seller.seller_info.ship_to_us === 0 || seller.seller_info.ship_to_us === "0" ? seller.seller_info.ship_to_us : 1,
                    "notes"             : (seller.seller_info.notes != null) ? seller.seller_info.notes : "",
                    "listing_price"     : list.listing_info.listing_price != null ? list.listing_info.listing_price : 0,
                    "currency"          : list.listing_info.currency != null ? list.listing_info.currency : "",
                    "brand_on_item"     : list.listing_info.brand_on_item_image != null ? list.listing_info.brand_on_item_image : 0,
                    "listing_price_max" : list.listing_info.listing_price_max,
                    "listing_price_unit": list.listing_info.listing_price_unit,
                    "logo_on_item"      : list.listing_info.logo_on_item_image != null ? list.listing_info.logo_on_item_image : 0,
                    "purchase_type"     : list.listing_info.purchase_type != null ? (list.listing_info.purchase_type ? list.listing_info.purchase_type : 0) : 'No need to purchase',
                    "transaction_number": list.listing_info.transaction_number,
                    "listing_location"  : list.listing_info.listing_location ? list.listing_info.listing_location : "",
                    "shipping_location" : list.listing_info.shipping_location != null ? (list.listing_info.shipping_location ? list.listing_info.shipping_location : 0) : 0,
                    "pymnt_platformname": list.listing_info.payment_platform,
                    "payment_platform"  : list.listing_info.payment_platform_id != null ? (list.listing_info.payment_platform_id ? list.listing_info.payment_platform_id : 0) : 0,
                    "payment_type"      : list.listing_info.payment_type_id,
                    "purchase_price"    : list.listing_info.purchase_price,
                    "item_type_id"      : list.listing_info.item_type_id != null ? (list.listing_info.item_type_id ? list.listing_info.item_type_id : 0) : 0,
                    "item_class"        : list.item_class,
                    "item_class_number" : list.item_class_number,
                    "substatus_id"      : list.substatus_id,
                    "evidences"         : list.evidences,
                    "qflag_id"          : list.qflag_id,
                    "general_history"   : list.general_history,
                    "seller_account_id" : list.seller_account_id,
                    "user_id"           : list.user_id,
                    "campaign"          : list.campaign,
                    "campaign_id"       : list.campaign_id,
                    "listing_number"    : list.listing_number,
                    "list_platform_id"  : list.platform_id,
                    "owner"             : list.verifier_owner != null
                                        ? (list.verifier_owner.first_name != null ? list.verifier_owner.first_name.trim() : "")+" "+(list.verifier_owner.last_name != null ? list.verifier_owner.last_name.trim() : "")
                                        : "",
                    "commission":list.for_commission,
                    "qa_user_id": list.qa_user_id,
                    "qa_owner"          : list.qa_owner != null
                                        ? (list.qa_owner.first_name != null ? list.qa_owner.first_name.trim() : "")+" "+(list.qa_owner.last_name != null ? list.qa_owner.last_name.trim() : "")
                                        : "",
                    "case_number": list.case_number,
                    'date_captured': list.listing_info?.date_captured ?? ''
                }
            })
            dispatch('setActiveListingStatus', true)
            commit("GET_ACTIVE_LISTING",listing_info)

            return listing_info
        }).catch(err=>{
            console.log(err)
            return false
        })
    },
    allowResearch({commit},bool){
        commit("ALLOW_RESEARCHED",bool)
    },
    allowSaveExit({ commit }, bool){
        commit("ALLOW_SAVE_EXIT",bool)
    },
    enablaDisabled({commit}, bool) {
        commit("ENABLE_DISABLED",bool)
    },
    updateShown({ commit }, value) {
        commit("UPDATE_SHOWN", value)
    },
    changeShowError({commit}) {
        commit("CHANGE_SHOW_ERROR");
    },
    getNotifications({commit, state}) {
        let headers = null;
        if (location.pathname.includes('/verification')) {
            headers = JSON.parse(localStorage.getItem('ver'));
        }
        else if (location.pathname.includes('/lawfirm')) {
            state.leadNotifications = [];
            commit("GET_NOTIFICATIONS", []);
            return new Promise(resolve => resolve([]));
        }
        else if (location.pathname.includes('/admin')) {
            // headers = JSON.parse(localStorage.getItem('admin'));
            state.leadNotifications = [];
            commit("GET_NOTIFICATIONS", []);
            return new Promise(resolve => resolve([]));
        }
        headers = JSON.parse(headers.headers);
        headers.headers.Sort = 'id,DESC';

        // return axios.get(`http://localhost:8000/notifications?page=${state.page_notification}&page_size=50`, headers)
        return axios.get(`${process.env.VUE_APP_URL}/notifications?page=${state.page_notification}&page_size=100`, headers)
            .then(response => {
                let notifications = response.data.data;

                state.leadNotifications = notifications

                commit("GET_NOTIFICATIONS", notifications);
                return notifications;
            })
            .catch(ex => {
                console.log(ex);
                return false;
            });
    },
    async startNotificationListener({commit, state, dispatch}, doInterval = false) {
        //temporary
        // if (!window.notification_listener) { // commented temporarily tried to make the request again after the previous request is done - Marvin 03/04/2022
        //     window.notification_listener = setInterval(function() {
        //         let headers = null;
        //         if (location.pathname.includes('/verification')) {
        //             headers = JSON.parse(localStorage.getItem('ver'));
        //         }
        //         else if (location.pathname.includes('/lawfirm')) {
        //             headers = JSON.parse(localStorage.getItem('lawfirm'));
        //         }
        //         else if (location.pathname.includes('/admin')) {
        //             headers = JSON.parse(localStorage.getItem('admin'));
        //         }
        //         if (headers == null) return false;
        //         headers = JSON.parse(headers.headers);
        //         headers.headers.Sort = 'id,DESC';
        //         // axios.get('http://localhost:8000/notifications?page=1&page_size=50', headers)
        //         axios.get(`${process.env.VUE_APP_URL}/notifications?page=1&page_size=50`, headers)
        //             .then(response => {
        //                 let latest = state.notifications.length > 0 ? state.notifications[0].id : null;
        //                 let unread = [];
        //                 let received = response.data.data;
        //                 for (let i = 0; i < received.length; i++) {
        //                     if (latest !== null && received[i].id <= latest) {
        //                         break;
        //                     }
        //                     received[i].moment_time = moment(received[i].created_at).format("MM/DD/YYYY LT");
        //                     unread.push(received[i]);
        //                 }

        //                 state.leadNotifications = response.data.data

        //                 commit("PREPEND_NOTIFICATION", unread);
        //             })
        //             .catch(ex => {
        //                 console.log(ex);
        //                 return false;
        //             });
        //     }, 20000);
        // }
    
    window.notification_listener = true

    let gui = location.pathname.includes('/verification')
        ? 'ver'
        : location.pathname.includes('/lawfirm') 
            ? 'lawfirm' 
            : location.pathname.includes('/admin')  
                ? 'admin'
                : null;
    
    if (gui === "lawfirm") {
        state.leadNotifications = [];
        return;
    }

    let headers = gui ? JSON.parse(localStorage.getItem(gui)) : null;
    if (headers == null) return false;
    headers = JSON.parse(headers.headers);
    headers.headers.Sort = 'id,DESC';
    // axios.get('http://localhost:8000/notifications?page=1&page_size=50', headers)
    return await axios.get(`${process.env.VUE_APP_URL}/notifications?page=1&page_size=50`, headers)
        .then(response => {
            let latest = state.notifications.length > 0 ? state.notifications[0].id : null;
            let unread = [];
            let received = response.data.data;
            for (let i = 0; i < received.length; i++) {
                if (latest !== null && received[i].id <= latest) {
                    break;
                }
                received[i].moment_time = moment(received[i].created_at).format("MM/DD/YYYY LT");
                unread.push(received[i]);
            }

            state.leadNotifications = response.data.data

            commit("PREPEND_NOTIFICATION", unread);
        })
        .catch(ex => {
            console.log(ex);
            return false;
        })
        .finally(() => {
            if (window.notification_listener && localStorage.getItem(gui) != null && doInterval) {
                setTimeout(() => {
                    dispatch('startNotificationListener')
                }, 1800000);
            }
        })

        //intentionally disable to avoid err_connection_timed_out error in console logs
        /*if (!window.Echo) {
            window.io = require('socket.io-client');
            window.Echo = new Echo({
                broadcaster: 'socket.io',
                host:`${new URL(process.env.VUE_APP_URL).protocol}//${new URL(process.env.VUE_APP_URL).hostname}:6001`,
                auth: {
                    headers: {
                        "Authorization" : `Bearer ${JSON.parse(localStorage.getItem('ver')).token}`
                    }
                }
            });
            window.Echo.private(`user.${JSON.parse(localStorage.getItem('ver')).id}`)
                .listen('ListingEvent', (e) => {
                    console.log(e);
                    let unread = e.data;
                    unread.moment_time = moment(unread.created_at).format("MM/DD/YYYY LT");
                    commit("PREPEND_NOTIFICATION", unread);
                });
        }*/
    },
    markAsRead({getters}, notification) {
        if (notification.read_at) {
            return true;
        }

        return axios.patch(
            `${process.env.VUE_APP_URL}/notification/${notification.id}`,
            {},
            getHeaders())
            .then(() => {
                notification.read_at = new Date();
                return true;
            })
            .catch(ex => {
                console.log(ex);
                return false;
            });
    },
    markAllAsRead({state, getters}) {
        return axios.patch(
            `${process.env.VUE_APP_URL}/notification/all`,
            {},
            getHeaders())
            .then(() => {
                state.notifications.forEach(n => n.read_at = new Date());
                return true;
            })
            .catch(ex => {
                console.log(ex);
                return false;
            });
    },
    addNotificationListener({commit}, callback) {
        commit("ADD_NOTIFICATION_LISTENER", callback);
    },
    clearNotifications({commit}) {
        // if (window.notification_listener) {
        //     clearInterval(window.notification_listener);
        // }
        /*if (window.Echo) {
            window.Echo.private(`user.${JSON.parse(localStorage.getItem('ver')).id}`).stopListening('ListingEvent');
            window.Echo = null;
            window.io = null;
        }*/

        window.notification_listener = false

        commit("CLEAR_NOTIFICATIONS");
    },
    setAdmFdStatus({commit}){ //set admin filter drawer status
        commit("SET_ADM_FD_STATUS")
    },
    setManualAdditionErrors({commit}, error){
        commit("SET_MANUAL_ADDITION_ERRORS", error)
    },
    reduceManualAdditionErrors({commit}, error){
        commit("REDUCE_MANUAL_ADDITION_ERRORS", error)
    },
    getFeatureAccess({commit}) {
        let access = 0
        let gui = location.pathname.includes('/lawfirm') ? 'lawfirm' : 'admin'

        if (!location.pathname.includes('/verification')) {
            access = ((JSON.parse(localStorage.getItem(gui)) || {}).case_status_filter || 0)
        }

        commit("STORE_FEATURE_ACCESS", access)
    },
    setSellerReference({commit, state}) {
        let reference = {}
        state.active_listing_info.forEach(info => {
            reference = {
                sellers : {
                    [info.seller_id] : {
                        name       : info.seller_name,
                        url        : info.seller_url,
                        platform_id: info.seller_platform,
                        site_id    : info.site_id,
                        contactable: info.is_contactable,
                        campaign_id: info.campaign_id,
                        info: {
                            contact_person    : info.contact_person,
                            mobile_no         : info.mobile_no,
                            telephone         : info.telephone,
                            e_mail            : info.e_mail,
                            first_contact_date: info.first_contact,
                            convo_method      : info.convo_method,
                            vpn_location      : info.vpn_location,
                            script            : info.script,
                            address           : info.address,
                            country           : info.country,
                            region            : info.region,
                            ship_to_us        : info.ship_to_us,
                            notes             : info.notes
                        }
                    }
                }
            }
        })

        commit('SET_SELLER_REFERENCE', reference)
    },
    setListingReference({commit, state}) {
        let reference = {}
        state.active_listing_info.forEach(info => {
            reference = {
                listings: {
                    [info.rid] : {
                        seller_id     : info.seller_id,
                        url           : info.list_url,
                        keyword       : info.keyword,
                        claim_type_id : info.claim_type_id,
                        platform_id   : info.list_platform_id,
                        listing_number: info.listing_number,
                        info: {
                            listing_title      : info.list_title,
                            item_type          : info.item_type_id,
                            dummy_account      : info.dummy_used,
                            listing_price      : info.listing_price,
                            listing_price_max  : info.listing_price_max || '',
                            currency           : info.currency,
                            listing_price_unit : info.listing_price_unit,
                            logo_on_item_image : info.logo_on_item,
                            brand_on_item_image: info.brand_on_item,
                            purchase_type      : info.purchase_type,
                            payment_platform   : info.payment_platform,
                            listing_location   : info.listing_location,
                            shipping_location  : info.shipping_location,
                            date_captured      : info.date_captured
                        }
                    }
                }
            }
        })
        commit('SET_LISTING_REFERENCE', reference)
    },
    checkFeatureAccess({commit, state}, access) {
        let allow = false
        let gui = location.pathname.includes('/lawfirm') ? 'lawfirm' : 'admin'
        let access_level = ((JSON.parse(localStorage.getItem(gui)) || {}).access_level || 0)
        const isCM = gui == 'lawfirm'

        if (isCM && [7].includes(access_level))
            return true

        if (isCM && [6, 3].includes(access_level) && access.includes(1))
            return true

        if (state.feature_access == 0)
            return allow

        if (access.some(a => state.feature_access.includes(a)))
            allow = true
        
        return allow
    },
    resetPostFilingFilters({commit}) {
        let value = {
            status               : "",
            selected_seller_flags: [],
            platforms            : []
        }

        commit('RESET_POST_FILING_FILTERS', value)
    },
    incrementViewKey({commit}) {
        //Re-mounting of component
        commit('INCREMENT_VIEW_KEY')
    },
    setSidebarCampaigns({commit}, campaigns) {
        commit('SET_SIDEBAR_CAMPAIGNS', campaigns)
    },
    reduceSidebarCampaignCount({commit}, campaign) {
        commit('REDUCE_SIDEBAR_CAMPAIGN_COUNT', campaign)
    },
    refreshCancelToken({commit}) {
        commit('REFRESH_CANCEL_TOKEN')
    },
    cancelRequests({commit}) {
        commit('CANCEL_REQUESTS')
    },
    ...verification,
    ...holidayTheme,
    ...counterfeitchecker,
    // added for Checking if done uploading Case File
    uploadedCaseFile({commit}, payload) {
        commit('uploadedCaseFile', payload)
    },
    deletedCaseFile({commit}, payload) {
        commit('deletedCaseFile', payload)
    },
    // setFiledListings({commit}, payload) {
    //     commit('setFiled_listings', payload)
    // },
    isGlobalSearch({commit}, payload) {
        commit('isGlobalSearch', payload)
    },
    globalSearchItem({commit}, payload) {
        commit('globalSearchItem', payload)
    },
    setGlobalSearch({commit}, payload) {
        commit('setGlobalSearch', payload)
    },
    setGlobalListings({commit}, payload) {
        commit('setGlobalListings', payload)
    },
    getPrev_route({commit}, payload) {
        commit('getPrev_route', payload)
    },
    /**
     * Use this to open the active listing info modal in Ver Listings
     * @param {string | number} payload listing id || listing number || rid
     */
    setKeywords({commit}, payload) {
        commit('setKeywords', payload)
    },
    setReassignClientListings({commit},payload) {
        commit('setReassignClientListings', payload)
    },
    showClientListings({state, commit, dispatch}, configPromise) {
        // console.log('configPromise in showClientListings VueX: ', configPromise)
        const gui_localStorage = getGuiSession()

        const user_id = gui_localStorage?.id
        
        const showClientListings_webSocketConfig = {}
        showClientListings_webSocketConfig[user_id] = {user_id: user_id, data: configPromise}
        

        if(typeof configPromise === 'boolean') {
            commit('showClientListings', configPromise)
        } else {
            Promise.resolve(configPromise)
                .then((config) => {
        
                    if(config.feature_access_store == null) return
                    
                    // console.log("has restricted client_listings: ",config.feature_access_store?.restrictedFeatures.includes('v-modal-client-listings'))
                    if (config.feature_access_store?.restrictedFeatures.includes('v-modal-client-listings')) {
                        // console.debug("showClientListings is restricted");
                        state.show_clientListings = false;
                        return;
                    }
        
                    // state.show_clientListings = config.hasClientListings
                    commit('showClientListings', config.hasClientListings)
                    showClientListings_webSocketConfig[user_id].data = config.hasClientListings
                    
                }).catch(error => {
                    console.log("Error resolving config: ", {error})
                    commit('showClientListings', false)
                    showClientListings_webSocketConfig[user_id].data = false
                })
        }
        dispatch('setShowClientListings_webSocketConfig', showClientListings_webSocketConfig)
    },
    async setListing_has_clientListings({state,commit,dispatch}, payload = "") {

        if(payload.user_id == undefined) {
            return
        }

        if (state.isLoading_clientListings) {
            console.log("Request already in progress, skipping...")
            return
        }

        commit("setIsLoading_clientListings", true) // Mark as loading

        /**
         * @param {String} payload
         * expecting "byCampaignLead" || ""
         */
        let headers = getHeaders()

        let client_listings = []
        let client_Listings_manualAddition = []

        const store = useFeatureAccessStore();

        dispatch('cancelRequests')
        dispatch('refreshCancelToken')

        headers.cancelToken = state.cancellationTokenSource.token

        try {
            const response = await axios.get(process.env.VUE_APP_URL + '/listings/priority?page_size=10000', headers);
            if (response.status === 200) {
                client_listings = response.data.data.map(c_list => ({
                    campaign_id: c_list.campaign_id,
                    campaign: c_list.campaign,
                    lawfirm: c_list.lawfirm,
                    logo: getImageCDN('campaigns', c_list.campaign || 'Default'),
                    product_img: c_list.evidences.preview || c_list.evidences.Preview,
                    image: getImageCDN('platforms', c_list.platform || 'Default'),
                    seller: c_list.seller[0]?.name || '------',
                    seller_url: c_list.seller[0]?.url || 'javascript: void(0)',
                    rid: c_list.id,
                    status: c_list.status,
                    detail: c_list.listing_info.listing_title,
                    product_url: c_list.url,
                    list_lawfirm: c_list.lawfirm,
                    reassigned: c_list.no_of_reassignment,
                    info: c_list.listing_info,
                    client_listing_id: c_list.client_listing_id,
                    client_name: c_list.client_name,
                    client_user_id: c_list.client_user_id,
                    campaign_name: c_list.client_name,
                    listing_url: c_list.listing_url,
                }));
                
                client_Listings_manualAddition = response.data.data
                    .filter(d => d.qflag_id === 4)
                    .map(c_list => ({
                        id: c_list.id,
                        status: c_list.status,
                        url: c_list.url,
                        name: c_list.seller[0]?.name,
                        title: c_list.listing_title,
                        researcher: `${c_list.verifier_owner.first_name} ${c_list.verifier_owner.last_name}`,
                        preview: c_list.evidences.preview || c_list.evidences.Preview,
                        qualifier: c_list.qualifier,
                        sellerURL: c_list.seller[0]?.url,
                    }));
            }
        } catch(error) {
            if (axios.isCancel(error)) {
                console.log("Request was cancelled:", {error});
            } else {
                console.error("Error:", {error});
            }
        } finally {
            try {
                console.debug({client_listings});

                commit("setIsLoading_clientListings", false)
            
                // Commit was used to trigger the function in the mutation.js

                const hasClientListings = client_listings.length > 0

                dispatch('setClient_listings', client_listings)
                dispatch('setClient_listings_manualAddition', client_Listings_manualAddition)

                const showClientListings_config = {
                    hasClientListings: hasClientListings,
                    feature_access_store: store.isFeatureRestrictionsReady ? store : null,
                }

                dispatch('showClientListings', showClientListings_config)

                // resetting Trigger
                dispatch('setHasTriggeredSomething', false)
            } catch(error) {
                console.error('Error in finally block:', error);
            }
        }
    },
    userAssignments({commit}) {
        // only request if gui is verification and ver token is not undefined/null

        /**
         * 11/28/24
         * Since the endpoint itself returns 400 if the user is not Researcher
         * access_level == 1
         * 
         * Adding Condition so that it will not request if user is not Researcher
         */
        
        const gui_localStorage = getGuiSession()
        const gui = getGui()
        
        if(gui !== 'ver') return

        if(gui_localStorage == null || gui_localStorage == undefined) return

        const access_level = gui_localStorage?.access_level
        const headers = JSON.parse(gui_localStorage.headers)

        if(access_level == 1) {
            axios.get(`${process.env.VUE_APP_URL}/api/user-assignments/current`, headers)
                .then(response => {
                    commit('getUserAssignments', response.data)
                }).catch(error => {
                    console.log({error})
                })
        }

        // if (location.pathname.includes('/verification') && localStorage.getItem('ver')) {
        //     let headers = JSON.parse(JSON.parse(localStorage.getItem('ver')).headers)
        //     axios.get(`${process.env.VUE_APP_URL}/api/user-assignments/current`, headers).
        //         then(response => {
        //                 // currentAssignedCampaign = response.data
        //                 commit('getUserAssignments', response.data)
        //             }
        //         ).catch(error => {
        //             console.log({error})
        //         })
        // }
    },
    setClient_listings({commit}, value) {
        commit('setClient_listings', value)
    },
    setClient_listings_manualAddition({commit}, value) {
        commit('setClient_listings_manualAddition', value)
    },
    setAddNewGroupListing({commit}, value) {
        commit('setAddNewGroupListing', value)
    },
    setResearchedTracker({commit}, value) {
        commit('setResearchedTracker', value)
    },
    setWaitingTracker({commit}, value) {
        commit ('setWaitingTracker', value)
    },
    setCongratulatoryMsg({commit}, value) {
        commit('setCongratulatoryMsg', value)
    },
    setResearcherTrackerLoad({commit}, value) {
        commit('setResearcherTrackerLoad', value)
    },
    getPlatformBreakdown2({commit}, value) {
        commit('getPlatformBreakdown2', value)
    },
    setTrigger({state, dispatch}, payload) {
        /**
         * accepts payload as
         * {
         *  label: string,
         *  value: boolean,
         * }
         * 
         *  Input the triggers here
         *  and use it to respective trigger points
         */
        let triggers = [
            'isTriggerOn_cancelEditListing', // done
            'isTriggerOn_saveAndExitEditListing', // done
            'isTriggerOn_cancelManualAddition', // done
            // 'isTriggerOn_addListing', // partial done - will be catered to saveAndExit Trigger
            'isTriggerOn_changeStatusFilters', // done
            'isTriggerOn_switchingCampaigns', // done -> Applied on Sidebar Campaigns
            'isTriggerOn_reassigningResearcherSide', // done -> Listing reassign
            'isTriggerOn_taskPageStatusChange', // done
            'isTriggerOn_verGUIListingStatusChange', // done
            'isTriggerOn_clientListingsModal', // done
            'isTriggerOn_taskPageMounted', // done
            'isTriggerOn_checkSellerAvailabilityManualAddition', // done
        ]

        if(triggers.includes(payload.label)) {
            // console.log('triggered from: ',payload.label) // triggered from
            if(payload.value == true) {
                state.hasTriggeredSomething = payload.value
                dispatch('setHasTriggeredSomething', payload.value)
            }
        }
    },
    setHasTriggeredSomething({commit}, value) {
        commit('setHasTriggeredSomething', value)
    },
    assignForValidation({commit}, value) {
        commit('UPDATE_STATE', { currentForValidation: value })
    },
    async validateForValidation({state}) {
        let current = state.currentForValidation
        let count = 0

        if (['0', '', null].includes(current.value)) {
            if (current.classList.contains('custom-chip-input')) {
                while (!current.classList.contains('custom-chip-textfield') && count < 10)
                    current = current["parent"]
    
                if (current.classList.contains('custom-chip-textfield'))
                    current.classList.add('is-invalid')
                
                return
            }
    
            current.classList.add('is-invalid')
            return
        }

        current.classList.remove('is-invalid')
    },
    async removeInvalidState({state, dispatch}, value) {
        dispatch('assignForValidation', value.target)
        
        await (500)

        let current = state.currentForValidation
        let currentValue = current.value

        if (current.getAttribute('type') == 'hidden') {
            current = current.parentElement.querySelector('.a-textfield input')
        }

        if (!['0', '', null].includes(currentValue))
            current.classList.remove('is-invalid')
    },
    setRelatedCampaignDuplicates({commit}, value) {
        commit('setRelatedCampaignDuplicates', value)
    },
    setRelatedCampaignDuplicateBypass({commit}, value) {
        commit('setRelatedCampaignDuplicateBypass', value)
    },
    setRelatedCampaignDuplicateContinueFunction({commit}, value) {
        commit('setRelatedCampaignDuplicateContinueFunction', value)
    },
    updateLawfirmCoverPage({commit}, value) {
        commit('UPDATE_STATE', { lawfirmCoverPage: value })
    },
    updatePaymentAccountsToBeAdded({commit}, value) {
        commit('updatePaymentAccountsToBeAdded', value)
    },
    /**
     * 
     * @param { Object } payload
     * @var {
     *  status: route.params.status,
     *  campaign_id: route.params.campaign_id,
     *  selected_payment_method: selected_payment_method,
     * } payload
     */
    async getCaseSellerAccount({commit,dispatch,state}, payload) {
        dispatch('cancelRequests')
        dispatch('refreshCancelToken')

        let headers       = getHeaders()
        headers.cancelToken = state.cancellationTokenSource.token
        if (payload.substatus_id && typeof payload.substatus_id != "undefined")
            headers.headers['Substatus-id'] = payload.substatus_id

        let data = {}
        await axios.get(`${process.env.VUE_APP_URL}/case_seller_accounts/${payload.status}/in/campaign/${payload.campaign_id}/with/${payload.selected_payment_method}?page_size=10000`,headers).then(
            response => {
                data.response_status = response.status == 200 ? 'success' : 'failed'
                if(response.status == 200) {
                    // console.log(response.data.data)
                    data.data = response.data.data
                    console.log('triggered getCaseSellerAccount in actions.js')
                }
            }
        ).catch(error => {
            let err = {error}
            if(err.error.message == undefined)
                console.log('store -> action.js -> getCaseSellerAccount() -> Cancelled Request')
            else
                console.log('store -> action.js -> getCaseSellerAccount() -> Error: ',error)
        })

        dispatch('setCaseSellerAccount', data)
        return data
    },
    setCaseSellerAccount({commit}, payload) {
        commit('setCaseSellerAccount', payload)
    },
    setMappedCaseSellerAccount({commit}, payload) {
        commit('setMappedCaseSellerAccount', payload)
    },
    setShowClientListings_webSocketConfig({commit},payload) {
        commit('setShowClientListings_webSocketConfig', payload)
    },
}
