import { defineStore } from 'pinia';
import { ref } from 'vue';
import { getGuiSession } from '@/utils/session';

export const useSubStatusesStore = defineStore('subStatusesStore', () => {
  const showListingsWithSubstatuses = ref(true);
  const subStatuses = ref({})
  const resetCurrentSelectedSubStatus = () => {
    currentSelectedSubStatus.value = null;
  }
  const currentSelectedSubStatus = ref(null);
  const currentSelectedSubStatusLabel = ref(null);
  const canSeeSubStatuses = ![3, 6].includes(getGuiSession().access_level)

  const adminMainStatuses = ref([])
  const adminSubstatusesGui = ref([])

  return {
    showListingsWithSubstatuses,
    subStatuses,
    resetCurrentSelectedSubStatus,
    canSeeSubStatuses,
    currentSelectedSubStatusLabel,
    adminMainStatuses,
    adminSubstatusesGui,
  };
});
