import AsyncModel from './asyncModel';
import VuexStore from '@/store/index';

export default class Campaign extends AsyncModel {
    static entity = 'campaigns';

    constructor(model, config) {
        super(model, config);
    }

    static fields() {
        return {
            id: this.number(null),
            name: this.string(null),
            campaign_priority: this.attr(null),
            status: this.attr(null),
        }
    }

    static apiConfig = {
        baseURL: 'api/v2/campaigns',
        dataKey: 'data'
    }

    static async loadCampaigns(params = null, force_request = false) {
        if(!force_request && (VuexStore.state.entities[this.entity].initialized || VuexStore.state.entities[this.entity].loading)) {
            return;
        }

        const default_params = {
            // filter: {
            //     status: 1,
            // },
            page_size: 10000,
        }
        await Campaign.api().get("", {
            params: params ?? default_params
        })
    }

    static getAllCampaigns() {
        return Campaign.query().get()
    }

    static getActiveCampaigns() {
        return Campaign.query()
            .where('status', (value) => [1].includes(value)).get()
    }
}