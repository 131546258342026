import AsyncModel from "./asyncModel";
import VuexStore from '@/store/index';

export default class User extends AsyncModel {

    // this.$store.state.entities['users']
    static entity = 'users';

    constructor(model, config) {
        super(model, config);
    }

    static fields() {
        return {
            id: this.number(null),
            first_name: this.attr(null),
            last_name: this.attr(null),
            access_level: this.number(null),
            e_mail: this.attr(null),
        }
    }

    static apiConfig = {
        baseURL: 'api/v2/users',
        dataKey: 'data'
    }

    static async loadUsers(params = null, force_request = false) {
        if(!force_request && (VuexStore.state.entities[this.entity].initialized || VuexStore.state.entities[this.entity].loading)) {
            return;
        }

        const default_params = {
            filter: {
                status: 1,
            },
            sort: "id",
            page_size: 10000,
        }
        await User.api().get("", {
            params: params ?? default_params
        })
    }

    static getUsers() {
        return User.query().get()
    }

    static getInternalUsers_exceptAdmin() {
        return User.query()
            .where('access_level', (value) => [1,2,5,7].includes(value)).get()
    }

    static getResearchersAndCL() {
        return User.query()
            .where('access_level', (value) => [1,5].includes(value)).get()
    }

    static getQA() {
        return User.query()
            .where('access_level', (value) => [2].includes(value)).get()
    }
}