const component = path => () => import(`@/components/pages/${path}.vue`)

const routes = [
    {
        path     : '/verification',
        name     : 'verification',
        component: component('verification/auth/VerLogin'),
        meta     : {title: 'Artemis | Verification'}
    },
    {
        path     : '/verification/my/tasks',
        name     : 'task',
        component: component('verification/Task'),
        meta     : {title: 'Verification | Tasks'}
    },
    {
        path     : "/verification/my/listings/campaign/:campaign_id",
        name     : "listing",
        component: component("verification/Listing"),
        meta     : {title: "Verification | Listing"},
        props    : true
    },
    {
        path     : "/verification/newlisting",
        name     : "newlisting",
        component: component("verification/NewListing"),
        meta     : {title: "Verification | New Listing"}
    },
    {
        path     : "/verification/listinginfo/campaign/:campaign_id",
        name     : "listinginfo",
        component: component("verification/ListingInfo"),
        meta     : {title: "Verification | Edit Listing"},
        props    : true
    },
    // Add New Listing buttons
    {
        path     : "/verification/manualaddition/campaign/:campaign_id",
        name     : "manualaddition",
        component: component("verification/ManualAddition"),
        meta     : {title: "Verification | Manual Addition "},
        props    : true
    },
    {
        path     : "/verification/auto-capture-listing",
        name     : "auto-capture-listing",
        component: component("verification/AutoCaptureListing"),
        meta     : {title: "Verification | Auto Capture Listings"},
    },
    // -----------------------------------
    {
        path     : "/verification/auto-capture-listing/qa",
        name     : "auto-capture-listing-qa",
        component: component("verification/AutoCaptureListing"),
        meta     : {title: "Verification | Auto Capture Monitoring"},
    },
    {
        path     : "/verification/my/campaigns",
        name     : "campaigns",
        component: component("verification/Campaign"),
        meta     : {title: "Verification | Campaign"}
    },
    {
        path     : "/verification/search",
        name     : "search",
        component: component("verification/Search"),
        meta     : {title: "Verification | Search Listings"}
    },
    {
        path     : "/verification/exportExcel/:campaign_id",
        name     : "export",
        component: component("verification/Download"),
        meta     : {title: "Verification | Export Excel"}
    },
    {
        path     : "/verification/notifications",
        name     : "notifications",
        component: component("verification/Notifications"),
        meta     : {title: "Verification | Notifications"}
    },
    {
        path     : "/verification/account-settings",
        name     : "user-profile-ver",
        component: component("verification/UserProfileVer"),
        meta     : {title: "Verification | Account Settings"}
    },
    {
        path     : "/verification/qa/start-shift",
        name     : "qa-start-shift",
        component: component("verification/StartShiftQA"),
        meta     : {title: "Verification | QA | Start Shift"}
        
    },
    {
        path     : "/verification/qa/listings",
        name     : "qa-listings-page",
        component: component("verification/ListingsQA"),
        meta     : {title: "Verification | QA | Listings"}
    },
    //HTTP pages
    {
        path     : "/verification/whou",
        name     : "vernosession",
        component: component("NoSession"),
        meta     : {title: "Verification | Session Expired"}
    },
    {
        path     : "/verification/holdup",
        name     : "vernotauthorized",
        component: component("NotAuthorized"),
        meta     : {title: "Verification | Not Authorized"}
    },
    {
        path     : '/verification/404',
        name     : 'pagenotfound',
        // component:lazyload("verification/Search"),
        component: ()=> import('@/components/layouts/PageNotFound'),
        meta     : {title: "Verification | Error 404"}
    },
    {
        path     : "/verification/*",
        redirect : "/verification/404"
    },
]

export default routes